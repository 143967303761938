<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <b-overlay
        id="overlay-background"
        :show="pageIsLoading"
        variant="light"
        opacity="0.85"
        blur="5px"
        rounded="sm"
      >
        <template #overlay>
          <b-card

            class="text-center"
          >

            <h2>
              <b-spinner

                class="mr-1"
                label="Loading"
              />
              {{ overlayMsg }}
            </h2>

          </b-card>
        </template>
        <div
          v-if="totalClients==0 && pageIsLoading==false"
          class="m-2"
        >
          <b-row>
            <b-col md="12">
              <div class="d-flex flex-column align-items-center">
                <feather-icon
                  icon="UsersIcon"
                  size="70"
                />
                <h1 class="mt-2">
                  Add customer details
                </h1>
                <span>Save your customers’ contact details for faster invoicing.</span>
                <b-button
                  class="mt-4"
                  variant="primary"
                  :to="{name:'clients-add'}"
                >
                  <span class="text-nowrap">{{ $t('Add Clients') }}</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>

        <div
          v-else
          class="m-2"
        >

          <!-- Table Top -->
          <b-row>

            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            />

            <!-- Search -->
            <b-col
              cols="12"
              md="6"
            >
              <div class="d-flex align-items-center justify-content-end">

                <b-button

                  variant="primary"
                  :to="{name:'clients-add'}"
                >
                  <span class="text-nowrap">{{ $t('Add Client') }}</span>
                </b-button>
              </div>
            </b-col>
          </b-row>

        </div>
        <div v-show="totalClients>0">
          <b-table

            ref="refClientListTable"
            class="position-relative"
            :items="fetchTickets"
            responsive
            :fields="tableColumns"
            primary-key="id"
            select-mode="single"
            striped
            hover
            show-empty
            selectable
            empty-text="No matching records found"
            @row-selected="onRowSelected"
          >
            <template #cell(name)="data">
              <span class="font-weight-bold d-block text-nowrap">{{ data.item.name }}</span>
            </template>

            <template #cell(created_at)="data">
              <span> {{ $format_date(data.item.created_at) }}</span>

            </template>
            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >

                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>

                <b-dropdown-item
                  variant="danger"
                  @click="deleteData(data.item.id)"
                >
                  <feather-icon icon="Trash2Icon" />
                  <span class="align-middle ml-50">{{ $t('Delete') }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>

          </b-table>
          <div class="mx-2 mb-2">
            <b-row>

              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted">{{ $t('Showing') }} {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} {{ $t('Entries') }}</span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >

                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalClients"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>

              </b-col>

            </b-row>
          </div>
        </div>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BDropdown, BDropdownItem, BPagination, BButton, BOverlay, BSpinner,
} from 'bootstrap-vue'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import {
  ref, onUnmounted, watch, computed,
} from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import clientStoreModule from './clientStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BButton,
    BOverlay,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
    }
  },
  methods: {
    onRowSelected(items) {
      this.$router.push({ name: 'clients-show', params: { id: items[0].id } })
    },
    deleteData(id) {
      this.$swal({
        title: this.$t('Are you sure ?'),
        text: this.$t('You are about to delete a client. This action cannot be undone'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Yes, delete now'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('app-client/deleteClient', { id })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Client is deleted',
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              this.$refs.refClientListTable.refresh()
            })
            .catch(() => {

            })
        }
      })
    },
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-client'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, clientStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    const toast = useToast()

    const perPageOptions = [10, 25, 50, 100]
    const perPage = ref(10)
    const searchQuery = ref('')
    const totalClients = ref(0)
    const refClientListTable = ref(null)
    const currentPage = ref(1)
    const pageIsLoading = ref(true)
    const tableColumns = [
      {
        key: 'name', label: 'Name', sortable: true,
      },
      { key: 'email', label: 'EMAIL', sortable: true },
      { key: 'created_at', label: 'Date added', sortable: true },
      {
 key: 'actions', label: 'ACTIONS', thClass: 'text-right', tdClass: 'text-right',
},
    ]

    const refetchData = () => {
      refClientListTable.value.refresh()
    }

    watch([currentPage, perPage, searchQuery], () => {
      refetchData()
    })
    const dataMeta = computed(() => {
      const localItemsCount = refClientListTable.value ? refClientListTable.value.localItems.length : 0
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalClients.value,
      }
    })
    const fetchTickets = (ctx, callback) => {
      store
        .dispatch('app-client/fetchClients', {
          q: searchQuery.value,
          perPage: perPage.value,
          page: currentPage.value,
        })
        .then(response => {
          pageIsLoading.value = false
          const { data } = response.data.data
          const { total } = response.data.meta
          callback(data)
          totalClients.value = total
        })
        .catch(() => {
          pageIsLoading.value = false
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching tickets list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
    return {

      tableColumns,
      // Filter
      perPageOptions,
      perPage,
      dataMeta,
      fetchTickets,
      searchQuery,
      totalClients,
      currentPage,
      refetchData,
      // UI
      refClientListTable,
      pageIsLoading,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
